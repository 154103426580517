import React, { useState, useEffect } from 'react';
import './css/crsTool.css'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

import ScoreCalculator from './scoreCalculator.js';

import LegalDisclaimer from './legalDisclaimer.js';

const Category = ({ title, onClick, expressEntry, expandedCategory }) => {
  const { t } = useTranslation();

  const toggleExpand = () => {
      onClick(title);
  };

  const selectedCategory = expressEntry.find(category => category.category === title);

  const isExpanded = expandedCategory === title;

  return (
      <div>
          {selectedCategory && (
              <div className='category' key={selectedCategory._id}>
                  <div className={`category-header ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
                      <span className='arrow'><FaArrowRight /></span>
                      <p className='mt-3'>{selectedCategory.category}</p>
                  </div>
                  {isExpanded && (
                      <div className='category-details'>
                          {selectedCategory.details.map((detail, index) => (
                              <table key={index}>
                                  <tbody>
                                      <tr>
                                          <td style={{ width: '65%' }}>{t("expressEntryDate")}:</td>
                                          <td>{detail.date}</td>
                                      </tr>
                                      <tr>
                                          <td style={{ width: '65%' }}>{t("expressEntryInvitations")}:</td>
                                          <td>{detail.invitationNumber >= 1000 ? detail.invitationNumber.toLocaleString() : detail.invitationNumber}</td>
                                      </tr>
                                      <tr>
                                          <td style={{ width: '65%' }}>{t("expressEntryCRS")}:</td>
                                          <td>{detail.CRS}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          ))}
                      </div>
                  )}
              </div>
          )}
      </div>
  );
};

const CategoryMobile = ({ title, onClick, expressEntry, expandedCategory }) => {
  const { t } = useTranslation();

  const toggleExpand = () => {
      onClick(title);
  };

  // Find the category with the matching title
  const selectedCategory = expressEntry.find(category => category.category === title);

  // Check if the category is expanded
  const isExpanded = expandedCategory === title;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  return (
      <div>
          {selectedCategory && (
              <div className='category' key={selectedCategory._id}>
                  <div className={`category-header ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
                    <>
                      {windowWidth > 960 ? (
                        <>
                          <span className='arrow-left'><FaArrowLeft /></span>
                          <p className='mt-3'>{selectedCategory.category}</p>
                        </>
                      ) : (
                        <>
                          <p className='mt-3'>{selectedCategory.category}</p>
                          <span className='arrow'><FaArrowRight /></span>
                        </>
                      )}
                    </>
                  </div>
                  {isExpanded && (
                      <div className='category-details'>
                          {selectedCategory.details.map((detail, index) => (
                              <table key={index}>
                                  <tbody>
                                      <tr>
                                          <td style={{ width: '65%' }}>{t("expressEntryDate")}:</td>
                                          <td>{detail.date}</td>
                                      </tr>
                                      <tr>
                                          <td style={{ width: '65%' }}>{t("expressEntryInvitations")}:</td>
                                          <td>{formatNumber(detail.invitationNumber)}</td>
                                      </tr>
                                      <tr>
                                          <td style={{ width: '65%' }}>{t("expressEntryCRS")}:</td>
                                          <td>{detail.CRS}</td>
                                      </tr>
                                      {detail.subcategory && (
                                          <tr>
                                              <td style={{ width: '65%' }}>{t("expressEntrySubcategory")}:</td>
                                              <td>{detail.subcategory}</td>
                                          </tr>
                                      )}
                                  </tbody>
                              </table>
                          ))}
                      </div>
                  )}
              </div>
          )}
      </div>
  );
};


const CrsTool = () => {
  const { t } = useTranslation();

  const [isToggle, setIsToggle] = useState(false);

  const handleExpandToggle = () => {
    setIsToggle(!isToggle);
  };

  const [isToggleMobile, setIsToggleMobile] = useState(false);

  const handleExpandToggleMobile = () => {
    setIsToggleMobile(!isToggle);
  };

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 240) { 
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [expandedCategory, setExpandedCategory] = useState(null);

  const handleCategoryClick = (title) => {
    setExpandedCategory(prevExpandedCategory => {
        return prevExpandedCategory === title ? null : title;
    });
  };

  const [maritalOption, setMaritalOption] = useState('');
  const [showSpouseQuestion, setShowSpouseQuestion] = useState(false);

  const handleMaritalChange = (event) => {
    const selectedMaritalOption = event.target.value;
    setMaritalOption(selectedMaritalOption);

    if(selectedMaritalOption === 'option2' || selectedMaritalOption === 'option3'){
      setShowSpouseQuestion(true);
      setShowAgeStatusQuestion(true);
      }
      else {
      setShowAgeStatusQuestion(true);
      setShowSpouseQuestion(false);
      setPartnerStatus('');
      setMovingStatus('');

      setShowSpouseEducationStatus(false);
      setSpouseEducationStatus('');
      setShowSpouseWorkStatus(false);
      setSpouseWorkStatus('');

      setShowSpouseLanguageTest(false);
      setSpouseLanguageTest('');
      setShowSpouseLanguageTestQuestion(false);
      setSpeakingTestSpouse('')
      setListeningTestSpouse('');
      setReadingTestSpouse('');
      setWritingTestSpouse('');
    }
  };


  const [partnerStatus, setPartnerStatus] = useState('');

  const handlePartnerStatus = (event) => {
    const selectedPartnerStatus = event.target.value;
    setPartnerStatus(selectedPartnerStatus);

    if (selectedPartnerStatus === 'option2') {
      setShowSpouseMovingQuestion(false);
      setShowAgeStatusQuestion(true);
    } else {
      setShowSpouseMovingQuestion(true);
      setMovingStatus('');
    }
  };


  const [movingStatus, setMovingStatus] = useState('');
  const [showSpouseMovingQuestion, setShowSpouseMovingQuestion] = useState(false);

  const handleMovingStatus = (event) => {
    const selectedMovingStatus = event.target.value;
    setMovingStatus(selectedMovingStatus);
    
    if (selectedMovingStatus === 'option1'){
      setShowAgeStatusQuestion(true);
      setShowSpouseEducationStatus(false);
      setShowSpouseWorkStatus(false);
      setShowSpouseLanguageTest(false);
      setShowSpouseLanguageTestQuestion(false);
      setSpeakingTestSpouse('');
      setListeningTestSpouse('');
      setReadingTestSpouse('');
      setWritingTestSpouse('');
    } else if (selectedMovingStatus === 'option2') {
      setShowAgeStatusQuestion(true);
    }
  };


  const [ageStatus, setAgeStatus] = useState('');
  const [showAgeStatusQuestion, setShowAgeStatusQuestion] = useState(false);

  const handleAgeStatus = (event) => {
    const selectedAgeStatus = event.target.value;
    setAgeStatus(selectedAgeStatus);

    if (selectedAgeStatus !== '') {
      setShowEducationStatusQuestion(true);
    } else {
      setShowEducationStatusQuestion(false);
      setEducationStatus('')
    }
  };


  const [educationStatus, setEducationStatus] = useState('');
  const [showEducationStatusQuestion, setShowEducationStatusQuestion] = useState(false);
  const [removedItem, setRemovedItem] = useState([]);
  const [removed, setRemoved] = useState(false);

  const handleEducationStatus = (event) => {
    const selectedEducationStatus = event.target.value;
    setEducationStatus(selectedEducationStatus);

    if (selectedEducationStatus === 'option1' || selectedEducationStatus === 'option2') {
      setShowCaEducationStatusQuestion(false);
      setShowCaEducationLevelStatusQuestion(false);
      setCaEducationStatus('');
      setCaEducationLevelStatus('');
      setShowLanguageStatusQuestion(true);
    } else {
      setShowCaEducationStatusQuestion(true);
    }

    if(selectedEducationStatus === 'option3' && removed === false) {
      setRemovedItem(caEducationLevelOptions.pop());
      setRemoved(true);
    } else if (selectedEducationStatus === 'option4' && removed === false) {
      setRemovedItem(caEducationLevelOptions.pop());
      setRemoved(true);
    }
    
    if((selectedEducationStatus !== 'option3') && caEducationLevelOptions.length < 2 && removed === true) {
      caEducationLevelOptions.push(removedItem);
    }
  };
  

  const [showLanguageStatusQuestion, setShowLanguageStatusQuestion] = useState(false);

  const [caEducationStatus, setCaEducationStatus] = useState('');
  const [showCaEducationStatusQuestion, setShowCaEducationStatusQuestion] = useState(false);

  const handleCaEducationStatus = (event) => {
    const selectedCaEducationStatus = event.target.value;
    setCaEducationStatus(selectedCaEducationStatus);

    if (selectedCaEducationStatus === 'option2') {
      setShowCaEducationLevelStatusQuestion(true);
    } else {
      setShowCaEducationLevelStatusQuestion(false);
      setShowLanguageStatusQuestion(true);
      setCaEducationLevelStatus('')
    }
  };


  const [caEducationLevelStatus, setCaEducationLevelStatus] = useState('');
  const [showCaEducationLevelStatusQuestion, setShowCaEducationLevelStatusQuestion] = useState(false);

  const handleCaEducationLevelStatus = (event) => {
    const selectedCaEducationLevelStatus = event.target.value;
    setCaEducationLevelStatus(selectedCaEducationLevelStatus);

    if (selectedCaEducationLevelStatus !== '') {
      setShowLanguageStatusQuestion(true);
    } else {
      setShowLanguageStatusQuestion(false);
    }
  };

  //------------------------Language Part-------------------------------

  const [languageTest, setLanguageTest] = useState('');
  const [showLanguageTestQuestion, setShowLanguageTestQuestion] = useState(false);

  const handleLanguageTest = (event) => {
    const selectedLanguageTest = event.target.value;
    const selectedLanguageTestLabel = languageTestOptions.find(option => option.value === selectedLanguageTest)?.label;
    setLanguageTest(selectedLanguageTest);
    setShowLanguageTestQuestion(true);

    axios.get(`https://northern-pathways-backend.onrender.com/api/languageTest/${selectedLanguageTestLabel}`)
      .then(response => {
        console.log('TestPoints Response:', response);
        setTestPoints(response.data.testPoints);
      })
      .catch(error => {
        console.error('Error fetching testPoints:', error);
      });
  };

  const [speakingTest, setSpeakingTest] = useState('');
  const [listeningTest, setListeningTest] = useState('');
  const [readingTest, setReadingTest] = useState('');
  const [writingTest, setWritingTest] = useState('');

  const handleSpeaking = (event) => {
    const selectedSpeaking = event.target.value;
    setSpeakingTest(selectedSpeaking);
  }

  const handleListening = (event) => {
    const selectedListening = event.target.value;
    setListeningTest(selectedListening);
  }

  const handleReading = (event) => {
    const selectedReading = event.target.value;
    setReadingTest(selectedReading);
  }

  const handleWriting = (event) => {
    const selectedWriting = event.target.value;
    setWritingTest(selectedWriting);
  }

  const checkAndSetShowSecondLanguageTest = () => {
    if (speakingTest !== '' && listeningTest !== '' && readingTest !== '' && writingTest !== '') {
      setShowSecondLanguageTest(true);
    } else {
      setShowSecondLanguageTest(false);
    }
  }


  const [speakingTestSecond, setSpeakingTestSecond] = useState('');
  const [listeningTestSecond, setListeningTestSecond] = useState('');
  const [readingTestSecond, setReadingTestSecond] = useState('');
  const [writingTestSecond, setWritingTestSecond] = useState('');

  const [secondLanguageTest, setSecondLanguageTest] = useState('');
  const [showSecondLanguageTest, setShowSecondLanguageTest] = useState(false);
  const [showSecondLanguageTestQuestion, setShowSecondLanguageTestQuestion] = useState(false);

  const checkSecondLanguageTest = () => {
    if (speakingTestSecond !== '' && listeningTestSecond !== '' && readingTestSecond !== '' && writingTestSecond !== '') {
      setShowWorkStatusQuestion(true);
    } else if (secondLanguageTest === 'option6'){
      setSpeakingTestSecond('')
      setListeningTestSecond('')
      setReadingTestSecond('')
      setWritingTestSecond('')
      setShowWorkStatusQuestion(true);
    } else {
      setShowWorkStatusQuestion(false);
    }
  }

  const handleSecondLanguageTest = (event) => {
    const selectedSecondLanguageTest = event.target.value;
    const selectedSecondLanguageTestLabel = languageTestOptions.find(option => option.value === selectedSecondLanguageTest)?.label;
    setSecondLanguageTest(selectedSecondLanguageTest);

    axios.get(`https://northern-pathways-backend.onrender.com/api/languageTest/${selectedSecondLanguageTestLabel}`)
      .then(response => {
        console.log('SecondTestPoints Response:', response);
        setSecondTestPoints(response.data.testPoints);
      })
      .catch(error => {
        console.error('Error fetching testPoints:', error);
      });

    if (selectedSecondLanguageTest !== 'option6') {
      setShowSecondLanguageTestQuestion(true);
    } else if (selectedSecondLanguageTest === 'option6') {
      setShowSecondLanguageTestQuestion(false);
    }
  };

  const handleSecondSpeaking = (event) => {
    const selectedSpeaking = event.target.value;
    setSpeakingTestSecond(selectedSpeaking);
  }
  
  const handleSecondListening = (event) => {
    const selectedListening = event.target.value;
    setListeningTestSecond(selectedListening);
  }
  
  const handleSecondReading = (event) => {
    const selectedReading = event.target.value;
    setReadingTestSecond(selectedReading);
  }
  
  const handleSecondWriting = (event) => {
    const selectedWriting = event.target.value;
    setWritingTestSecond(selectedWriting);
  }
  
  useEffect(() => {
    checkAndSetShowSecondLanguageTest();
  }, [speakingTest, listeningTest, readingTest, writingTest, languageTest]);

  useEffect(() => {
    checkSecondLanguageTest();
  }, [speakingTestSecond, listeningTestSecond, readingTestSecond, writingTestSecond, secondLanguageTest]);


//---------------------------------------------------------------------------------------

  const [workStatus, setWorkStatus] = useState('');
  const [showWorkStatusQuestion, setShowWorkStatusQuestion] = useState(false);

  const handleWorkStatus = (event) => {
    const selectedWorkStatus = event.target.value;
    setWorkStatus(selectedWorkStatus);

    if(selectedWorkStatus !== '') {
      setShowForeignWorkStatusQuestion(true);
    }
  };


  const [foreignWorkStatus, setForeignWorkStatus] = useState('');
  const [showForeignWorkStatusQuestion, setShowForeignWorkStatusQuestion] = useState(false);

  const handleForeignWorkStatus = (event) => {
    const selectedForeignWorkStatus = event.target.value;
    setForeignWorkStatus(selectedForeignWorkStatus);

    if(selectedForeignWorkStatus !== '') {
      setShowCertificateStatus(true);
    }
  };


  const [certificateStatus, setCertificateStatus] = useState('');
  const [showCertificateStatus, setShowCertificateStatus] = useState(false);

  const handleCertificateStatus = (event) => {
    const selectedCertificateStatus = event.target.value;
    setCertificateStatus(selectedCertificateStatus);

    if(selectedCertificateStatus !== '') {
      setShowAdditionalStatusQuestion(true);
    }
  };


  const [additionalStatus, setAdditionalStatus] = useState('');
  const [showAdditionalStatusQuestion, setShowAdditionalStatusQuestion] = useState(false);

  const handleAdditionalStatus = (event) => {
    const selectedAdditionalStatus = event.target.value;
    setAdditionalStatus(selectedAdditionalStatus);

    if(selectedAdditionalStatus === 'option1') {
      setNocStatus('');
      setShowNocStatusQuestion(false);
      setShowNominationStatusQuestion(true);
    } else {
      setShowNocStatusQuestion(true);
    }
  };



  const [nocStatus, setNocStatus] = useState('');
  const [showNocStatusQuestion, setShowNocStatusQuestion] = useState(false);

  const handleNOCStatus = (event) => {
    const selectedNocStatus = event.target.value;
    setNocStatus(selectedNocStatus);

    if (selectedNocStatus !== '') {
      setShowNominationStatusQuestion(true);
    }
  };


  const [nominationStatus, setNominationStatus] = useState('');
  const [showNominationStatusQuestion, setShowNominationStatusQuestion] = useState(false);

  const handleNominationStatus = (event) => {
    const selectedNominationStatus = event.target.value;
    setNominationStatus(selectedNominationStatus);

    if(selectedNominationStatus !== ''){
      setShowLawPartnerStatusQuestion(true);
    }
  };
  

  const [lawPartnerStatus, setLawPartnerStatus] = useState('');
  const [showLawPartnerStatusQuestion, setShowLawPartnerStatusQuestion] = useState(false);

  const handleLawPartnerStatus = (event) => {
    const selectedLawPartnerStatus = event.target.value;
    setLawPartnerStatus(selectedLawPartnerStatus);
  };


  const [spouseEducationStatus, setSpouseEducationStatus] = useState('');
  const [showSpouseEducationStatus, setShowSpouseEducationStatus] = useState(false);

  const handleSpouseEducationStatus = (event) => {
    const selectedSpouseEducationStatus = event.target.value;
    setSpouseEducationStatus(selectedSpouseEducationStatus);

    if (selectedSpouseEducationStatus !== ''){
      setShowSpouseWorkStatus(true);
    }
  };


  const [spouseWorkStatus, setSpouseWorkStatus] = useState('');
  const [showSpouseWorkStatus, setShowSpouseWorkStatus] = useState(false);

  const handleSpouseWorkStatus = (event) => {
    const selectedSpouseWorkStatus = event.target.value;
    setSpouseWorkStatus(selectedSpouseWorkStatus);

    if (selectedSpouseWorkStatus !== ''){
      setShowSpouseLanguageTest(true);
    }
  };


  const [spouseLanguageTest, setSpouseLanguageTest] = useState('');
  const [showSpouseLanguageTest, setShowSpouseLanguageTest] = useState(false);
  const [showSpouseLanguageTestQuestion, setShowSpouseLanguageTestQuestion] = useState(false);

  const handleSpouseLanguageTest = (event) => {
    const selectedSpouseLanguageTest = event.target.value;
    const selectedSpouseLanguageTestLabel = languageTestOptions.find(option => option.value === selectedSpouseLanguageTest)?.label;
    setSpouseLanguageTest(selectedSpouseLanguageTest);

    axios.get(`https://northern-pathways-backend.onrender.com/api/languageTest/${selectedSpouseLanguageTestLabel}`)
      .then(response => {
        console.log('SpouseTestPoints Response:', response);
        setSpouseTestPoints(response.data.testPoints);
      })
      .catch(error => {
        console.error('Error fetching testPoints:', error);
      });

      if (selectedSpouseLanguageTest === 'option6') {
        setShowSpouseLanguageTestQuestion(false);
        setSpeakingTestSpouse('');
        setListeningTestSpouse('');
        setReadingTestSpouse('');
        setWritingTestSpouse('');
      } else {
        setShowSpouseLanguageTestQuestion(true);
      }
  };

  const [speakingTestSpouse, setSpeakingTestSpouse] = useState('');
  const [listeningTestSpouse, setListeningTestSpouse] = useState('');
  const [readingTestSpouse, setReadingTestSpouse] = useState('');
  const [writingTestSpouse, setWritingTestSpouse] = useState('');

  const handleSpouseSpeaking = (event) => {
    const selectedSpeaking = event.target.value;
    setSpeakingTestSpouse(selectedSpeaking);
  }

  const handleSpouseListening = (event) => {
    const selectedListening = event.target.value;
    setListeningTestSpouse(selectedListening);
  }

  const handleSpouseReading = (event) => {
    const selectedReading = event.target.value;
    setReadingTestSpouse(selectedReading);
  }

  const handleSpouseWriting = (event) => {
    const selectedWriting = event.target.value;
    setWritingTestSpouse(selectedWriting);
  }

  const checkSpouseStatus = () => {
    if ((maritalOption === 'option2' || maritalOption === 'option5') && (movingStatus === 'option2') && (lawPartnerStatus !== '')) {
      setShowSpouseEducationStatus(true);
    } 
  }

  useEffect(() => {
    checkSpouseStatus();
  }, [maritalOption, movingStatus, lawPartnerStatus]);

  useEffect(() => {
    if (additionalStatus === 'option2') {
      if (nocStatus === ''){
        setShowNominationStatusQuestion(false);
        setNominationStatus('');
        setShowLawPartnerStatusQuestion(false);
        setLawPartnerStatus('');
        setShowSpouseEducationStatus(false);
        setSpouseEducationStatus('');
        setSpouseWorkStatus(false);
        setSpouseWorkStatus('');
        setShowSpouseLanguageTest(false);
        setSpouseLanguageTest('');
        setShowSpouseLanguageTestQuestion(false);
        setSpeakingTestSpouse('');
        setListeningTestSpouse('');
        setReadingTestSpouse('');
        setWritingTestSpouse('');
      }
    }
  }, [additionalStatus, nocStatus]);

  const [expressEntry, setExpressEntry] = useState([]);
  //--------------------------------------------------------

  const [maritalOptions, setMaritalOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [partnerMovingOptions, setPartnerMovingOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [caEducationOptions, setCaEducationOptions] = useState([]);
  const [caEducationLevelOptions, setCaEducationLevelOptions] = useState([]);
  const [languageStatusOptions, setLanguageStatusOptions] = useState([]);
  const [languageTestOptions, setLanguageTestOptions] = useState('');
  const [testPoints, setTestPoints] = useState({ speaking: [], listening: [], reading: [], writing: [] });
  const [secondTestPoints, setSecondTestPoints] = useState({ speaking: [], listening: [], reading: [], writing: [] });
  const [workStatusOptions, setWorkStatusOptions] = useState([]);
  const [foreignWorkStatusOptions, setForeignWorkStatusOptions] = useState([]);
  const [certificateStatusOptions, setCertificateStatusOptions] = useState([]);
  const [additionalStatusOptions, setAdditionalStatusOptions] = useState([]);
  const [nocStatusOptions, setNocStatusOptions] = useState([]);
  const [nominationStatusOptions, setNominationStatusOptions] = useState([]);
  const [lawPartnerStatusOptions, setLawPartnerStatusOptions] = useState([]);

  const rearrangeOptions = (options, startOption) => {
    return options.sort((a, b) => {
      const valueA = parseInt(a.value.replace('option', ''));
      const valueB = parseInt(b.value.replace('option', ''));
  
      if (valueA === startOption) {
        return -1; // Move 'a' to a lower index if its value matches the startOption
      } else if (valueB === startOption) {
        return 1; // Move 'b' to a lower index if its value matches the startOption
      } else {
        return valueA - valueB; // Otherwise, sort normally
      }
    });
  };

  useEffect(() => {
    // Get selected language from local storage
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en'; 

    //1
    //https://northern-pathways-backend.onrender.com
    // Define API endpoints based on the selected language
    const maritalEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/maritalStatusTR' : 'https://northern-pathways-backend.onrender.com/api/maritalStatus';
    const partnerEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/partnerStatusTR' : 'https://northern-pathways-backend.onrender.com/api/partnerStatus';
    const partnerMovingEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/movingStatusTR' : 'https://northern-pathways-backend.onrender.com/api/movingStatus';
    const ageEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/ageStatusTR' : 'https://northern-pathways-backend.onrender.com/api/ageStatus';
    const educationEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/educationStatusTR' : 'https://northern-pathways-backend.onrender.com/api/educationStatus';
    const caEducationEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/caEducationStatusTR' : 'https://northern-pathways-backend.onrender.com/api/caEducationStatus';
    const caEducationLevelEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/caEducationLevelStatusTR' : 'https://northern-pathways-backend.onrender.com/api/caEducationLevelStatus';
    const languageStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/languageStatusTR' : 'https://northern-pathways-backend.onrender.com/api/languageStatus';
    const languageTestEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/languageTestTR' : 'https://northern-pathways-backend.onrender.com/api/languageTest';
    const workStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/workStatusTR' : 'https://northern-pathways-backend.onrender.com/api/workStatus';
    const foreignWorkStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/foreignWorkStatusTR' : 'https://northern-pathways-backend.onrender.com/api/foreignWorkStatus';
    const certificateStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/certificateStatusTR' : 'https://northern-pathways-backend.onrender.com/api/certificateStatus';
    const additionalStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/additionalStatusTR' : 'https://northern-pathways-backend.onrender.com/api/additionalStatus';
    const nocStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/nocStatusTR' : 'https://northern-pathways-backend.onrender.com/api/nocStatus';
    const nominationStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/nominationStatusTR' : 'https://northern-pathways-backend.onrender.com/api/nominationStatus';
    const lawPartnerStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/lawPartnerStatusTR' : 'https://northern-pathways-backend.onrender.com/api/lawPartnerStatus';
    const expressEntryEndPoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/expressEntryTR' : 'https://northern-pathways-backend.onrender.com/api/expressEntry';

    // Fetch data for marital status
    axios.get(maritalEndpoint)
      .then(response => {
        console.log('Marital Response:', response);
        const sortedMaritalOptions = rearrangeOptions(response.data, 1); // Start with option1
        setMaritalOptions(sortedMaritalOptions);
      })
      .catch(error => {
        console.error('Error fetching marital status:', error);
      });

    // Fetch data for partner status
    axios.get(partnerEndpoint)
      .then(response => {
        console.log('Partner Response:', response);
        const sortedPartnerOptions = rearrangeOptions(response.data, 1); // Start with option1
        setPartnerOptions(sortedPartnerOptions);
      })
      .catch(error => {
        console.error('Error fetching partner status:', error);
      });

    // Fetch data for partner moving status
    axios.get(partnerMovingEndpoint)
      .then(response => {
        console.log('Partner Moving Response:', response);
        const sortedPartnerMovingOptions = rearrangeOptions(response.data, 1); // Start with option1
        setPartnerMovingOptions(sortedPartnerMovingOptions);
      })
      .catch(error => {
        console.error('Error fetching partner moving status:', error);
      });

    // Fetch data for age status
    axios.get(ageEndpoint)
      .then(response => {
        console.log('Age Response:', response);
        const sortedAgeOptions = rearrangeOptions(response.data, 1); // Start with option1
        setAgeOptions(sortedAgeOptions);
      })
      .catch(error => {
        console.error('Error fetching age status:', error);
      });

    // Fetch data for education status
    axios.get(educationEndpoint)
      .then(response => {
        console.log('Education Response:', response);
        const sortedEducationOptions = rearrangeOptions(response.data, 1); // Start with option1
        setEducationOptions(sortedEducationOptions);
        const sortedSpouseEducationStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setSpouseEducationStatusOptions(sortedSpouseEducationStatusOptions);
      })
      .catch(error => {
        console.error('Error fetching education status:', error);
      });

    // Fetch data for CA education status
    axios.get(caEducationEndpoint)
      .then(response => {
        console.log('CA Education Response:', response);
        const sortedCaEducationOptions = rearrangeOptions(response.data, 1); // Start with option1
        setCaEducationOptions(sortedCaEducationOptions);
      })
      .catch(error => {
        console.error('Error fetching CA education status:', error);
      });

    // Fetch data for CA education level status
    axios.get(caEducationLevelEndpoint)
      .then(response => {
        console.log('CA Education Level Response:', response);
        const sortedCaEducationLevelOptions = rearrangeOptions(response.data, 1); // Start with option1
        setCaEducationLevelOptions(sortedCaEducationLevelOptions);
      })
      .catch(error => {
        console.error('Error fetching CA education level status:', error);
      });

    // Fetch data for language status
    axios.get(languageStatusEndpoint)
      .then(response => {
        console.log('Language Status Response:', response);
        const sortedLanguageStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setLanguageStatusOptions(sortedLanguageStatusOptions);
      })
      .catch(error => {
        console.error('Error fetching language status:', error);
      });

    // Fetch data for language test options
    axios.get(languageTestEndpoint)
      .then(response => {
        console.log('Language Test Response:', response);
        const sortedLanguageTestOptions = rearrangeOptions(response.data, 1); // Start with option1
        setLanguageTestOptions(sortedLanguageTestOptions);
        const sortedSpouseLanguageTestOptions = rearrangeOptions(response.data, 1); // Start with option1
        setSpouseLanguageTestOptions(sortedSpouseLanguageTestOptions);
      })
      .catch(error => {
        console.error('Error fetching language test options:', error);
      });

    // Fetch data for work status
    axios.get(workStatusEndpoint)
      .then(response => {
        console.log('Work Status Response:', response);
        const sortedWorkStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setWorkStatusOptions(sortedWorkStatusOptions);
        const sortedSpouseWorkStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setSpouseWorkStatusOptions(sortedSpouseWorkStatusOptions);
      })
      .catch(error => {
        console.error('Error fetching work status:', error);
      });

    // Fetch data for foreign work status
    axios.get(foreignWorkStatusEndpoint)
      .then(response => {
        console.log('Foreign Work Status Response:', response);
        const sortedForeignWorkStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setForeignWorkStatusOptions(sortedForeignWorkStatusOptions);
      })
      .catch(error => {
        console.error('Error fetching foreign work status:', error);
      });

    // Fetch data for certificate status
    axios.get(certificateStatusEndpoint)
      .then(response => {
        console.log('Certificate Status Response:', response);
        const sortedCertificateStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setCertificateStatusOptions(sortedCertificateStatusOptions);
      })
      .catch(error => {
        console.error('Error fetching certificate status:', error);
      });

    // Fetch data for additional status
    axios.get(additionalStatusEndpoint)
      .then(response => {
        console.log('Additional Status Response:', response);
        const sortedAdditionalStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setAdditionalStatusOptions(sortedAdditionalStatusOptions);
      })
      .catch(error => {
        console.error('Error fetching additional status:', error);
      });

    // Fetch data for NOC status
    axios.get(nocStatusEndpoint)
      .then(response => {
        console.log('NOC Status Response:', response);
        const sortedNocStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setNocStatusOptions(sortedNocStatusOptions);
      })
      .catch(error => {
        console.error('Error fetching NOC status:', error);
      });

    // Fetch data for nomination status
    axios.get(nominationStatusEndpoint)
      .then(response => {
        console.log('Nomination Status Response:', response);
        const sortedNominationStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setNominationStatusOptions(sortedNominationStatusOptions);
      })
      .catch(error => {
        console.error('Error fetching nomination status:', error);
      });

    // Fetch data for law partner status
    axios.get(lawPartnerStatusEndpoint)
      .then(response => {
        console.log('Law Partner Status Response:', response);
        const sortedLawPartnerStatusOptions = rearrangeOptions(response.data, 1); // Start with option1
        setLawPartnerStatusOptions(sortedLawPartnerStatusOptions);
      })
      .catch(error => {
        console.error('Error fetching law partner status:', error);
      });

    axios.get(expressEntryEndPoint)
      .then(response => {
          console.log('Categories after fetching express entry status:', expressEntry); // Log the updated categories
          const rearrangedData = response.data.sort((a, b) => a.value - b.value);
          setExpressEntry(rearrangedData);
      })
      .catch(error => {
          console.error('Error fetching express entry status:', error);
      });


  }, [localStorage.getItem('selectedLanguage')]);

  const [spouseEducationStatusOptions, setSpouseEducationStatusOptions] = useState([]);
  const [spouseWorkStatusOptions, setSpouseWorkStatusOptions] = useState([]);
  const [spouseLanguageTestOptions, setSpouseLanguageTestOptions] = useState([]);
  const [spouseTestPoints, setSpouseTestPoints] = useState([]);

  useEffect(() => {
    // Reset the values when languageTest changes
    setSpeakingTest('');
    setListeningTest('');
    setReadingTest('');
    setWritingTest('');
  }, [languageTest]);

  useEffect(() => {
    // Reset the values when languageTest changes
    setSpeakingTestSecond('');
    setListeningTestSecond('');
    setReadingTestSecond('');
    setWritingTestSecond('');
  }, [secondLanguageTest]);

  useEffect(() => {
    // Reset the values when languageTest changes
    setSpeakingTestSpouse('');
    setListeningTestSpouse('');
    setReadingTestSpouse('');
    setWritingTestSpouse('');
  }, [spouseLanguageTest]);

  useEffect(() => {
    if(maritalOption === 'option2' || maritalOption === 'option3') {
      if(partnerStatus === 'option1'){
        if(movingStatus === 'option2'){
          if(lawPartnerStatus !== '') {
            setShowSpouseEducationStatus(true);
          }
        }
      }
    }
  }, [maritalOption, partnerStatus, movingStatus, lawPartnerStatus]);

  useEffect(() => {
    if (showSecondLanguageTest === false) {
      setSecondLanguageTest('');
      setSpeakingTestSecond('');
      setListeningTestSecond('');
      setReadingTestSecond('');
      setWritingTestSecond('');
    }
  });

  return (
    <>
      <div className={`${isToggleMobile ? 'toggle-background' : 'main-container'}`} >
        <div className='legal-disclaimer'>
          <LegalDisclaimer />
        </div>
        <div className="dropdown-container">
          <div className='container'>
          <h1 className='text-2xl font-bold'>{t("crs")}</h1>
          <ol className='list-decimal'>
            <li>
              <p>{t("maritalStatusQuestion")}</p>
              <select className="selection" id="marital-status" value={maritalOption} onChange={handleMaritalChange}>
              <option value="" disabled>{t("select")}</option>
              {maritalOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
              </select>
            </li>

            {showSpouseQuestion && (
              <li>
                <p>{t("spouseQuestion")}</p>
                <select className="selection" id="partner-status" value={partnerStatus} onChange={handlePartnerStatus}>
                  <option value="" disabled>{t("select")}</option>
                  {partnerOptions.map(option => 
                    <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>

                {showSpouseMovingQuestion && (
                <ul className='list-disc'>
                  <li>
                    <p>{t("spouseMovingQuestion")}</p>
                    <select className="selection" id="moving-status" value={movingStatus} onChange={handleMovingStatus}>
                      <option value="" disabled>{t("select")}</option>
                      {partnerMovingOptions.map(option => 
                        <option key={option.value} value={option.value}>{option.label}</option>
                      )}
                    </select>
                  </li>
                </ul>
                )}
              </li>
            )}


            {showAgeStatusQuestion && (
              <li>
                <p>{t("ageQuestion")}</p>
                <select className="selection" id="age-status" value={ageStatus} onChange={handleAgeStatus}>
                  <option value="" disabled>{t("select")}</option>
                  {ageOptions.map(option => 
                    <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>
              </li>
            )}

            {showEducationStatusQuestion && (
              <li>
                <p>{t("educationStatusQuestion")}</p>
                <ul className='list-disc'>
                  <li>{t("educationStatusElibilityLi1")}</li>
                  <li>{t("educationStatusElibilityLi2")}</li>
                </ul>
                <select className="selection-education" id="education-status" value={educationStatus} onChange={handleEducationStatus}>
                  <option value="" disabled>{t("select")}</option>
                  {educationOptions.map(option => 
                    <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>
              </li>
            )}

            {showCaEducationStatusQuestion && (
              <li>
                <p>{t("CaEducationStatusQuestion")}</p>
                <p><b>{t("CaEducationStatusElibility")}:</b></p>
                <ul className='list-disc'>
                  <li>{t("CaEducationStatusElibilityLi1")}</li>
                  <li>{t("CaEducationStatusElibilityLi2")}</li>
                  <li>{t("CaEducationStatusElibilityLi3")}</li>
                  <li>{t("CaEducationStatusElibilityLi4")}</li>
                  <li>{t("CaEducationStatusElibilityLi5")}</li>
                  <li>{t("CaEducationStatusElibilityLi6")}</li>
                </ul>
                <select className="selection" id="ca-education-status" value={caEducationStatus} onChange={handleCaEducationStatus}>
                  <option value="" disabled>{t("select")}</option>
                  {caEducationOptions.map(option => 
                    <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>
              </li>
            )}

              {showCaEducationLevelStatusQuestion && (
                <ul className='list-disc'>
                  <li>
                    <p>{t("CaEducationLevelStatusQuestion")}</p>
                    <select className="selection-education" id="ca-education-level-status" value={caEducationLevelStatus} onChange={handleCaEducationLevelStatus}>
                      <option value="" disabled>{t("select")}</option>
                      {caEducationLevelOptions.map(option => 
                        <option key={option.value} value={option.value}>{option.label}</option>
                      )}
                    </select>
                  </li>
                </ul>
              )}

            {showLanguageStatusQuestion && (
              <li>
                <p>{t("languageStatusDescription")}</p>
                <p style={{textDecoration: "underline"}}><i>{t("languageStatusWarning")}</i></p>
                  <ul className='list-disc'>
                    <li>
                      <p>{t("languageQuestionFirst")}</p>
                      <select className="selection" id="language-test" value={languageTest} onChange={handleLanguageTest}>
                        <option value="" disabled>{t("select")}</option>
                        {languageTestOptions.slice(0, -1).map(option => 
                          <option key={option.value} value={option.value}>{option.label}</option>
                        )}
                      </select>
                                          
                    </li>
                  </ul>

                  {showLanguageTestQuestion && (
                        <ul className='list-disc test'>
                          <li>
                            <p>{t("speaking")}:</p>
                            <select className="selection" id="CELPIP-G-speaking" value={speakingTest} onChange={handleSpeaking}>
                              <option value="" disabled>{t("select")}</option>
                              {testPoints.speaking && testPoints.speaking.map((point, index) => (
                                <option key={index} value={point}>
                                  {point}
                                </option>
                              ))}
                            </select>
                          </li>

                          <li>
                            <p>{t("listening")}:</p>
                            <select className="selection" id="CELPIP-G-listening" value={listeningTest} onChange={handleListening}>
                              <option value="" disabled>{t("select")}</option>
                              {testPoints.listening && testPoints.listening.map((point, index) => (
                                <option key={index} value={point}>
                                  {point}
                                </option>
                              ))}
                            </select>
                          </li>

                          <li>
                            <p>{t("reading")}:</p>
                            <select className="selection" id="CELPIP-G-reading" value={readingTest} onChange={handleReading}>
                              <option value="" disabled>{t("select")}</option>
                              {testPoints.reading && testPoints.reading.map((point, index) => (
                                <option key={index} value={point}>
                                  {point}
                                </option>
                              ))}
                            </select>
                          </li>

                          <li>
                            <p>{t("writing")}:</p>
                            <select className="selection" id="CELPIP-G-writing" value={writingTest} onChange={handleWriting}>
                              <option value="" disabled>{t("select")}</option>
                              {testPoints.writing && testPoints.writing.map((point, index) => (
                                <option key={index} value={point}>
                                  {point}
                                </option>
                              ))}
                            </select>
                          </li>
                        </ul>
                    )}
                

                {showSecondLanguageTest && (
                  <ul className='list-disc'>
                    <li>
                      <p>{t("languageQuestionSecond")}</p>
                      <p style={{textDecoration: "underline"}}><i>{t("languageStatusWarning")}</i></p>
                      <select className="selection" id="second-language-test" value={secondLanguageTest} onChange={handleSecondLanguageTest}>
                        <option value="" disabled>{t("select")}</option>
                        {languageTest === 'option1' || languageTest === 'option2' || languageTest === 'option3' ? (
                          languageTestOptions.slice(3, 6).map(option => 
                            <option key={option.value} value={option.value}>{option.label}</option>
                          )
                        ) : languageTest === 'option4' || languageTest === 'option5' ? (
                          [
                            ...(languageTestOptions[0] ? [{ key: languageTestOptions[0].value, value: languageTestOptions[0].value, label: languageTestOptions[0].label }] : []),
                            ...(languageTestOptions[1] ? [{ key: languageTestOptions[1].value, value: languageTestOptions[1].value, label: languageTestOptions[1].label }] : []),
                            ...(languageTestOptions[1] ? [{ key: languageTestOptions[2].value, value: languageTestOptions[2].value, label: languageTestOptions[2].label }] : []),
                            ...(languageTestOptions[5] ? [{ key: languageTestOptions[5].value, value: languageTestOptions[5].value, label: languageTestOptions[5].label }] : [])
                          ].map(option => 
                            <option key={option.key} value={option.value}>{option.label}</option>
                          )
                        ) : null}
                      </select>
                  
                    </li>

                    {showSecondLanguageTestQuestion && (
                        <ul className='list-disc'>
                          <li>
                            <p>{t("speaking")}:</p>
                            <select className="selection" id="CELPIP-G-speaking" value={speakingTestSecond} onChange={handleSecondSpeaking}>
                              <option value="" disabled>{t("select")}</option>
                              {secondTestPoints.speaking && secondTestPoints.speaking.map((point, index) => (
                                <option key={index} value={point}>
                                  {point}
                                </option>
                              ))}
                            </select>
                          </li>

                          <li>
                            <p>{t("listening")}:</p>
                            <select className="selection" id="CELPIP-G-listening" value={listeningTestSecond} onChange={handleSecondListening}>
                              <option value="" disabled>{t("select")}</option>
                              {secondTestPoints.listening && secondTestPoints.listening.map((point, index) => (
                                <option key={index} value={point}>
                                  {point}
                                </option>
                              ))}
                            </select>
                          </li>

                          <li>
                            <p>{t("reading")}:</p>
                            <select className="selection" id="CELPIP-G-reading" value={readingTestSecond} onChange={handleSecondReading}>
                              <option value="" disabled>{t("select")}</option>
                              {secondTestPoints.reading && secondTestPoints.reading.map((point, index) => (
                                <option key={index} value={point}>
                                  {point}
                                </option>
                              ))}
                            </select>
                          </li>

                          <li>
                            <p>{t("writing")}:</p>
                            <select className="selection" id="CELPIP-G-writing" value={writingTestSecond} onChange={handleSecondWriting}>
                              <option value="" disabled>{t("select")}</option>
                              {secondTestPoints.writing && secondTestPoints.writing.map((point, index) => (
                                <option key={index} value={point}>
                                  {point}
                                </option>
                              ))}
                            </select>
                          </li>
                        </ul>
                      )}
                  </ul>
                )}
              </li>
            )}      

            {showWorkStatusQuestion && (
              <li>
                <p><b>{t("CaWorkExperience")}</b></p>
                <p>{t("CaWorkExperienceDescription")}</p>

                <ul className='list-disc'>
                  <li>{t("CaWorkExperienceDescriptionList1")}</li>
                  <li>{t("CaWorkExperienceDescriptionList2")}</li>
                  <li>{t("CaWorkExperienceDescriptionList3")}</li>
                  <li>{t("CaWorkExperienceDescriptionList4")}</li>
                  <li>{t("CaWorkExperienceDescriptionList5")}</li>
                </ul>

                <select className="selection" id="work-status" value={workStatus} onChange={handleWorkStatus}>
                  <option value="" disabled>{t("select")}</option>
                  {workStatusOptions.map(option => 
                    <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>
              </li>
            )}

              {showForeignWorkStatusQuestion && (
                    <li>
                      <p><b>{t("ForeignWorkExperience")}</b></p>
                      <p>{t("ForeignWorkExperienceQuestion")}</p>
                      <p><i>*{t("ForeignWorkExperienceElibility")}</i></p>
                      <select className="selection" id="foreignWork-status" value={foreignWorkStatus} onChange={handleForeignWorkStatus}>
                        <option value="" disabled>{t("select")}</option>
                        {foreignWorkStatusOptions.map(option => 
                          <option key={option.value} value={option.value}>{option.label}</option>
                        )}
                      </select>
                    </li>
                )}
            
            {showCertificateStatus && (
              <li>
                <p>{t("certificateStatusQuestion")}</p>
                <p dangerouslySetInnerHTML={{ __html: t('certificateStatusDescription') }} />
                <select className="selection" id="certificate-status" value={certificateStatus} onChange={handleCertificateStatus}>
                  <option value="" disabled>{t("select")}</option>
                  {certificateStatusOptions.map(option => 
                    <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>
              </li>
            )}

            
            {showAdditionalStatusQuestion && (
              <li>
                <p className='font-bold underline'>{t("additionalPoint")}</p>
                <p>{t("additionalPointDescription")} 
                  <a
                      href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/offer-employment/lmia-exempt.html "
                      className="font-bold text-blue-400 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    ({t("additionalPointDescriptionNeeded")})
                  </a>{" "} 
                </p> 
                <p>
                    {t("additionalPointQualification")} 
                    <a
                        href="https://www.northernpathways.ca/pre-assessment-form"
                        className="font-bold text-blue-400 underline"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("additionalPointQualificationBold")} 
                    </a>{" "} 
                    {t("additionalPointQualificationCont")}
                </p>
                <select className="selection" id="additional-points" value={additionalStatus} onChange={handleAdditionalStatus}>
                  <option value="" disabled>{t("select")}</option>
                  {additionalStatusOptions.map(option => 
                    <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>

                {showNocStatusQuestion && (
                  <ul className='list-disc'>
                    <li>
                      <p>{t("nocQuestion")}</p>
                      <select className="selection" id="noc-status" value={nocStatus} onChange={handleNOCStatus}>
                        <option value="" disabled>{t("select")}</option>
                        {nocStatusOptions.map(option => 
                          <option key={option.value} value={option.value}>{option.label}</option>
                        )}
                      </select>
                    </li>
                  </ul>
                )}
              </li>
            )}
            
            {showNominationStatusQuestion && (
              <li>
                <p>{t("nominationQuestion")}</p>
                <select className="selection" id="nomination-status" value={nominationStatus} onChange={handleNominationStatus}>
                  <option value="" disabled>{t("select")}</option>
                  {nominationStatusOptions.map(option => 
                      <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>
              </li>
            )}

            {showLawPartnerStatusQuestion && (
              <li>
                <p>{t("lawPartnerStatusQuestion")}</p>
                <select className="selection" id="lawPartner-status" value={lawPartnerStatus} onChange={handleLawPartnerStatus}>
                  <option value="" disabled>{t("select")}</option>
                  {lawPartnerStatusOptions.map(option => 
                      <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>
              </li>
            )}

            {showSpouseEducationStatus && (
              <li>
                <p>{t("spouseEducationQuestion")}</p>
                <select className="selection" id="spouseEducation-status" value={spouseEducationStatus} onChange={handleSpouseEducationStatus}>
                    <option value="" disabled>{t("select")}</option>
                    {spouseEducationStatusOptions.map(option => 
                      <option key={option.value} value={option.value}>{option.label}</option>
                    )}
                </select>
              </li>
            )}

            {showSpouseWorkStatus && (
              <li>
                <p>{t("spouseWorkStatus")}</p>
                <select className="selection" id="spouseWork-status" value={spouseWorkStatus} onChange={handleSpouseWorkStatus}>
                    <option value="" disabled>{t("select")}</option>
                    {spouseWorkStatusOptions.map(option => 
                      <option key={option.value} value={option.value}>{option.label}</option>
                    )}
                  </select>
              </li>
            )}

            {showSpouseLanguageTest && (
              <li>
                <p>{t("spouseLanguageTestQuestion")}</p>
                <p>{t("languageStatusDescription")}</p>
                <p style={{textDecoration: "underline"}}><i>{t("languageStatusWarning")}</i></p>
                <p>{t("languageQuestionSpouse")}</p>
                <select className="selection" id="spouseLanguage-test" value={spouseLanguageTest} onChange={handleSpouseLanguageTest}>
                  <option value="" disabled>{t("select")}</option>
                  {spouseLanguageTestOptions.map(option => 
                    <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </select>
  
                {showSpouseLanguageTestQuestion && (
                <ul className='list-disc'>
                    <li>
                      <p>{t("speaking")}</p>
                      <select className="selection" id="language-test" value={speakingTestSpouse} onChange={handleSpouseSpeaking}>
                        <option value="" disabled>{t("select")}</option>
                        {spouseTestPoints.speaking && spouseTestPoints.speaking.map((point, index) => (
                          <option key={index} value={point}>
                            {point}
                          </option>
                        ))}
                      </select>
                    </li>

                    <li>
                      <p>{t("listening")}:</p>
                      <select className="selection" id="language-test" value={listeningTestSpouse} onChange={handleSpouseListening}>
                        <option value="" disabled>{t("select")}</option>
                        {spouseTestPoints.listening && spouseTestPoints.listening.map((point, index) => (
                          <option key={index} value={point}>
                            {point}
                          </option>
                        ))}
                      </select>
                    </li>

                    <li>
                      <p>{t("reading")}</p>
                      <select className="selection" id="language-test" value={readingTestSpouse} onChange={handleSpouseReading}>
                        <option value="" disabled>{t("select")}</option>
                        {spouseTestPoints.reading && spouseTestPoints.reading.map((point, index) => (
                          <option key={index} value={point}>
                            {point}
                          </option>
                        ))}
                      </select>
                    </li>

                    <li>
                      <p>{t("writing")}</p>
                      <select className="selection" id="language-test" value={writingTestSpouse} onChange={handleSpouseWriting}>
                        <option value="" disabled>{t("select")}</option>
                        {spouseTestPoints.writing && spouseTestPoints.writing.map((point, index) => (
                          <option key={index} value={point}>
                            {point}
                          </option>
                        ))}
                      </select>
                    </li>
                  </ul>
                  )}
              </li>
            )}
          </ol>

            <ScoreCalculator
              maritalOption={maritalOption}
              partnerStatus={partnerStatus}
              movingStatus={movingStatus}
              ageStatus={ageStatus}
              educationStatus={educationStatus}
              caEducationStatus={caEducationStatus}
              caEducationLevelStatus={caEducationLevelStatus}
              languageTest={languageTest}
              speakingTest={speakingTest}
              listeningTest={listeningTest}
              readingTest={readingTest}
              writingTest={writingTest}
              secondLanguageTest={secondLanguageTest}
              speakingTestSecond={speakingTestSecond}
              listeningTestSecond={listeningTestSecond}
              readingTestSecond={readingTestSecond}
              writingTestSecond={writingTestSecond}
              workStatus={workStatus}
              foreignWorkStatus={foreignWorkStatus}
              certificateStatus={certificateStatus}
              additionalStatus={additionalStatus}
              nocStatus={nocStatus}
              nominationStatus={nominationStatus}
              lawPartnerStatus={lawPartnerStatus}
              spouseEducationStatus={spouseEducationStatus}
              spouseWorkStatus={spouseWorkStatus}
              spouseLanguageTest={spouseLanguageTest}
              speakingTestSpouse={speakingTestSpouse}
              listeningTestSpouse={listeningTestSpouse}
              readingTestSpouse={readingTestSpouse}
              writingTestSpouse={writingTestSpouse}
            />

          </div>
        </div>

        {/*
        <div className={isFixed ? 'fixed-divMobile' : 'normal-divMobile'}>
          <div className={`toggle ${isToggle ? 'expandToggle' : ''}`} >
            <div className="content">
              <div className='toggle-header' onClick={handleExpandToggle}>
                <p>{t("expressEntryTitle")}</p>
                <span className='arrow-left'><FaArrowLeft /></span>
              </div>
              <div className='expand-content'>
                {expressEntry.map((category, index) => (
                    <CategoryMobile
                        key={index}
                        title={category.category}
                        onClick={handleCategoryClick}
                        expressEntry={expressEntry}
                        expandedCategory={expandedCategory}
                    />
                ))}
              </div>
            </div>
          </div> 
        </div>
        */}
      </div>

    </>
  );
};

export default CrsTool;
